
































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const model = reactive({
      index: "",
      status: "",
      file: null as any,
    });

    const state = reactive({
      error: false as boolean | number,
    });

    const fillModel = () => {
      model.index = props.item.index;
    };
    onMounted(fillModel);

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const formData = new FormData();

      if (model.index) formData.append("index", model.index);
      if (model.file) formData.append("file", model.file);

      axiosInstance
        .put(
          `event/${root.$route.params.id}/poster/${props.item.id}`,
          formData,
          {
            headers: {
              event: root.$route.params.id,
            },
            params: {
              fileType: "posterSessionModule",
            },
          }
        )
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.poster.edit.success"),
          });
          setTimeout(() => {
            emit("close");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };
    return { model, getFile, onSubmit };
  },
});
